<template>
  <div class="home">
    <!-- banner大图 -->
    <div class="banner">
      <img-div
        class-name="flex flex-vc flex-v"
        :img-url="require('@/assets/home/1@2x.png')"
        height="320"
      >
        <img :src="require(`@/assets/home/slogan.png`)" class="slogan" />
        <div class="sloganText">-优秀的软件开发服务商-</div>
      </img-div>
    </div>
    <div class="advantage">
      <!-- 优势列表 -->
      <div
        class="advantage-list"
        v-for="(item, index) in advantageList"
        :key="index"
      >
        <div
          :class="[
            'advantage-item flex flex-vc flex-hc container',
            index % 2 === 0 ? '' : 'flex-zRightLeft',
          ]"
        >
          <img
            :src="item.imgSrc"
            alt=""
            class="advantage-item_leftImg"
            v-animate-onscroll="{
              down: 'animate__animated animate__fadeInUp',
            }"
          />
          <div
            :class="['advantage-item_right', index % 2 === 0 ? '' : '']"
            v-animate-onscroll="{
              down: 'animate__animated animate__fadeInUp',
            }"
          >
            <div class="advantage-item_right_title">
              {{ item.title }}
            </div>
            <div class="advantage-item_right_desc">
              {{ item.desc }}
            </div>
            <ul>
              <li
                class="flex flex-vc regulations"
                v-for="(liItem, idx) in item.liList"
                :key="idx"
              >
                <img :src="liItem.iconSrc" class="regulations_img" alt="" />
                <span>{{ liItem.text }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImgDiv from "@/components/base/ImgDiv.vue";
import api from "@/api";
export default {
  components: {
    ImgDiv,
  },
  computed: {
    advantageList() {
      return this.$t("home.advantageList");
    },
  },
  data() {
    return {
      noticeList: [],
      showNotice: true,
    };
  },
  created() {
    // console.log(this.$i18n.locale);
    api.common
      .noticeList()
      .then((res) => {
        if (res.list && res.list.length > 0) this.noticeList = res.list;
      })
      .catch((err) => {
        console.error(err);
      });
    this.$nextTick(() => {
      window.scrollTo(1, 1);
    });
  },
};
</script>
<style lang="scss" scoped>
.home {
  @keyframes fadeInUps {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, 30%, 0);
      transform: translate3d(0, 30%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }
  .animate__fadeInUp {
    -webkit-animation-name: fadeInUps;
    animation-name: fadeInUps;
  }
  background-color: var(--whiteColor);
  .banner {
    position: relative;
    .slogan {
      width: 571px;
      height: 51px;
      margin-top: 124px;
    }
    .sloganText {
      font-size: 18px;
      color: var(--whiteColor);
      line-height: 25px;
      margin-top: 29px;
    }
  }
  .advantage {
    .advantage-list {
      overflow: hidden;
      &:nth-child(even) {
        background-color: var(--bgGray);
        .advantage-item_right {
          margin-right: 100px;
          margin-left: 0;
        }
      }
      // &:nth-child(1) {
      //   .advantage-item {
      //     width: 1070px;
      //     .advantage-item_right {
      //       padding-right: 240px;
      //     }
      //   }
      // }
      // &:nth-child(2) {
      //   .advantage-item {
      //     width: 1115px;
      //   }
      // }
      // &:nth-child(3) {
      //   .advantage-item {
      //     width: 1115px;
      //     .advantage-item_right {
      //       padding-right: 200px;
      //     }
      //   }
      // }
      // &:nth-child(4) {
      //   .advantage-item {
      //     width: 1115px;
      //   }
      // }
    }
    &-item {
      padding: 80px 0 76px 0px;
      &_leftImg {
        width: 520px;
      }
      .ml20 {
        margin-left: 20px;
      }
      &_right {
        font-size: 28px;
        line-height: 40px;
        color: var(--blackColor);
        margin-left: 100px;
        &_title {
          font-size: 40px;
          line-height: 56px;
          font-weight: 600;
        }
        &_desc {
          margin-top: 8px;
          font-size: 24px;
          line-height: 33px;
          color: var(--iconGray);
        }
        ul {
          margin-top: 24px;
          font-size: 20px;
          line-height: 28px;
          .regulations {
            padding-top: 26px;
            &_img {
              width: 30px;
              margin-right: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
